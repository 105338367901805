import React, { Component } from 'react';
import * as queries from './queries';


class QuickSightEmbed extends Component {
  constructor(props){
    super(props);
  }
  
  render(){
    //this is a blank page for you to modify
    return (
      <div style={{height:window.innerHeight, width:'100%'}}>
        <h2>
          This page is currently under construction. Thank you for your patience.
        </h2>
      </div>
    );
  }
}

export default QuickSightEmbed;
