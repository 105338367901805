import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';


class Nav extends Component {
  constructor(props){
    super(props);
    
    this.handleLogOut = this.handleLogOut.bind(this);
  }
  
  //handleLogOut signes the user out of the app
  handleLogOut(){
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }
  
  render(){
    //pages is an array of internal pages for use in the nav bar. It may be beneficial to make this a global property using mobx or another state manager so it can be used to create routes as well
    const pages = [
      {
        id:'embedPage',
        displayName:'CONSOLE',
        path:'/',
      },
      {
        id:'internalPage',
        displayName:'TAG LINK',
        path:'/internal',
      },
    ];
    return (
      <div style={{width:'100%', height:100, display:'flex', alignItems:'center', justifyContent:'flex-end', backgroundColor:'white'}}>
        <img style={{height:90, width:95, backgroundColor:'white', position: 'absolute', left: 50, margin:5, top:3 }} src={'https://static.wixstatic.com/media/7db617_25035f9956da4a7ca6f37e0987fea3a8~mv2.png/v1/fill/w_216,h_208,al_c,usm_0.66_1.00_0.01/Green%20Logo.png'}/>
        {/*mapped Links to internal pages*/}
        {pages.map(page =>
          <Link to={page.path} style={{ textDecoration: 'none' }}>
            <p style={{color:'#0B3554', margin:20}}>
              {page.displayName}
            </p>
          </Link>
        )}
        {/*anchor for external page*/}
        <a style={{color:'#0B3554', textDecoration: 'none', margin:20}} href='https://strongbo-agtech.com'>
          STRONGBO
        </a>
        {/*paragraph for logout button*/}
        <p style={{backgroundColor:'#e47911', color:'white', padding: 15, margin:20}} onClick={this.handleLogOut}>
          Logout
        </p>
      </div>
    );
  }
}

export default withRouter(Nav);
