import React, { Component } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import * as queries from './queries';


class QuickSightEmbed extends Component {
  constructor(props){
    super(props);
  }
  
  componentDidMount(){
    //when the component mounts, get the user's information from cognito
    Auth.currentUserInfo()
      .then(user => {
        //after the user's information is retrieved, use it's email attribute to query the API for the embed URL
        API.graphql(graphqlOperation(
          queries.getQuickSightURL,
          {email:user.attributes.email}))
            .then(response => {
              //use the embed URL to embed the dashboard in the container provided
              embedDashboard({
                url:response.data.getQuickSightURL.url,
                container:document.getElementById('dashboardContainer'),
              });
            })
            //handle errors. Currently the app only logs to the console.
            .catch(error => console.log(error)
        )
      })
  }
  
  render(){
    return (
      <div style={{height:window.innerHeight, width:'100%'}}>
          <div id='dashboardContainer' style={{height:'100%', width:'100%'}}/>
      </div>
    );
  }
}

export default QuickSightEmbed;
