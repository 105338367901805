import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/*
  this is the configuration information to allow the app to connect to your AWS environment
*/

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-west-1:03ee7563-f33b-453b-be92-51cafd0c6179',
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_lE3zi7CcK',
    userPoolWebClientId: '5s0binb373dfbjnuqqe5ec3o78',
    mandatorySignIn: true,
  },
  aws_appsync_graphqlEndpoint: 'https://eram4vdzync6pbg3jv2cvhyj6i.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
