import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import QuickSightEmbed from './QuickSightEmbed';
import InternalPage from './InternalPage';
import Nav from './Nav';

//Theme test
const MyTheme = {
    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' }
}



class App extends Component {
  constructor(props){
    super(props);
  }
  
  render(){
    return (
      <div className="App">
        <header className='App-header'>
          {/*The Router component controlls the navigation of the app*/}
          <Router>
            {/*Nav is a navigation bar containing all of the links exposed to the user */}
            <Nav/>
            {/*The Routes are the page components users can navigate to within the app*/}
            <Route path='/' exact component={QuickSightEmbed}/>
            <Route path='/internal' exact component={InternalPage}/>
          </Router>
        </header>
      </div>
    );
  }
}

//With Authenticator provides all of the neccesary authentication ui and functionality
export default withAuthenticator(App,MyTheme);
