/*
  this is the graphql query to get the quicksight url
*/

export const getQuickSightURL = `query GetQuickSightURL($email: String!) {
  getQuickSightURL(email: $email) {
    url
  }
}
`;
